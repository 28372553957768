import ROUTES from 'routes/paths';
// @components
import { Icon } from '@mui/material';
import { USER_ROLES } from 'utils/constants';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon sx={{ width: '100%', height: '100%' }} children={name} />;

const ICONS = {
  home: getIcon('home'),
  user: getIcon('groups'),
  contest: getIcon('emoji_events'),
  problem: getIcon('code'),
  submission: getIcon('history_edu'),
  ranking: getIcon('leaderboard'),
  editor: getIcon('create'),
  return: getIcon('west'),
};

export const defaultSidebar = [
  {
    subheader: 'general',
    items: [
      {
        title: 'Contest',
        path: ROUTES.CONTEST_LIST,
        icon: ICONS.contest,
      },
      {
        title: 'Problem',
        path: ROUTES.PROBLEM_LIST,
        icon: ICONS.problem,
      },
      {
        title: 'Submission',
        path: ROUTES.SUBMISSION_LIST,
        icon: ICONS.submission,
      },
      // {
      //   title: 'Leaderboard',
      //   path: ROUTES.LEADERBOARD,
      //   icon: ICONS.ranking,
      // },
    ],
  },
];

export const adminSidebar = [
  {
    subheader: 'admin',
    items: [
      {
        title: 'Contest',
        path: ROUTES.ADMIN_CONTEST_LIST,
        icon: ICONS.contest,
      },
      {
        title: 'Editor',
        path: ROUTES.ADMIN_EDITOR,
        icon: ICONS.editor,
        role: USER_ROLES.Manager,
      },
      {
        title: 'User',
        path: ROUTES.ADMIN_USER_LIST,
        icon: ICONS.user,
        role: USER_ROLES.Admin,
      },
    ],
  },
  {
    items: [
      {
        title: 'Return home',
        path: ROUTES.DASHBOARD_ROOT,
        icon: ICONS.return,
      },
    ],
  },
];
