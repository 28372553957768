import { Page } from '@components';
import { styled } from '@mui/styles';
import { Box, Container, Typography } from '@mui/material';
import SubpageHeroSection from '../components/SubpageHeroSection';
import { HERO_DATA, TRAINING_STEPS } from './config';
import TrainingSyllabus from './components/TrainingSyllabus';

const RootStyle = styled(Page)({
  height: '100%',
  minHeight: '100vh',
});

export default function TrainingPage() {
  return (
    <RootStyle title="Training" id="move_top" container={false}>
      <SubpageHeroSection data={HERO_DATA} />
      <Container maxWidth="lg" sx={{ my: 10 }}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h2" textAlign="center" sx={{ mb: 1 }}>
            Lộ trình đào tạo
          </Typography>
        </Box>

        <TrainingSyllabus data={TRAINING_STEPS} />
      </Container>
    </RootStyle>
  );
}
