import moment from 'moment-mini';

export const DateFormat = Object.freeze({
  DDMMYYYYhhmmss: 'DD/MM/YYYY HH:mm:ss',
  DDMMYYYYhhmm: 'DD/MM/YYYY HH:mm',
  DDMMYYYY: 'DD/MM/YYYY',
  YYYYMMDD: 'YYYY-MM-DD',
  hhmmDDMMYYYY: 'HH:mm, DD/MM/YYYY',
});
/**
 * @function formatDate
 * Format date into specialized format
 * @param {Date | string | number} date Input date
 * @param { 'DDMMYYYYhhmmss' | 'DDMMYYYYhhmm' | 'DDMMYYYY' | 'YYYYMMDD' | 'hhmmDDMMYYYY' } [format='DDMMYYYY'] Specialized format
 * @returns {string} Formatted date
 */
export default function formatDate(date, format = 'DDMMYYYY') {
  if (!moment(date).isValid()) return '';
  return moment(date).format(DateFormat[format]);
}
