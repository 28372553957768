import { useCallback } from 'react';
import { debounce } from 'lodash';

/**
 * @param { function} fn
 * @param timeOut
 * @return {DebouncedFunc<(...args: any) => any>}
 */
const useDebounce = (fn, timeOut) => useCallback(debounce(fn, timeOut), []);

export default useDebounce;
