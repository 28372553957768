import { useTranslation } from 'react-i18next';
// material
import { enUS, viVN } from '@mui/material/locale';
import { LOCAL_STORAGE_KEYS } from 'utils/constants';

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'emojione-v1:flag-for-united-states',
  },
  {
    label: 'Tiếng Việt',
    value: 'vi',
    systemValue: viVN,
    icon: 'emojione-v1:flag-for-vietnam',
  },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.i18Lang);
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[0];

  const handleChangeLanguage = (newLang) => {
    i18n.changeLanguage(newLang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
