export const ProblemLevel = Object.freeze({
  EASY: 0,
  MEDIUM: 1,
  HARD: 2,
  0: 'Easy',
  1: 'Medium',
  2: 'Hard',
});

export const MAX_TESTS = 20;
