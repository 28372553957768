import Label from '@components/Label';
import { Box, Icon, IconButton, InputAdornment, Link, TextField, Tooltip } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { ProblemLevel } from 'contants/problem';
import { isNil } from 'lodash';
import { CodeService } from 'services';
import { useEffect } from 'react';
import { useDebounce, usePagination, useToast } from 'hooks';

import { Link as RouterLink, useParams } from 'react-router-dom';
import ROUTES from 'routes/paths';
import { formatDate } from 'utils';

function ProblemLinkCellRender({ row, value }) {
  return (
    <Link component={RouterLink} to={ROUTES.PROBLEM_DETAIL.replace(':problemId', row.id)} underline="none">
      {value}
    </Link>
  );
}

function SubmissionLinkCellRender({ row, value }) {
  const { contestId = 0 } = useParams();
  return (
    <Link
      component={RouterLink}
      to={ROUTES.CONTEST_SUBMISSION_LIST.replace(':contestId', contestId)}
      state={{ problemId: row.id }}
      underline="none"
    >
      {value}
    </Link>
  );
}

function DoneCellRender({ value }) {
  if (value === true) return <Icon sx={{ color: '#00AB55' }} children="done" />;
  if (value === false) return <Icon sx={{ color: 'red' }} children="close" />;
  return '';
}

const problemLevelColor = ['success', 'warning', 'error'];

const columns = [
  {
    field: 'id',
    headerName: '#',
    hide: true,
  },
  {
    field: 'code',
    headerName: '#',
    flex: 1,
    renderCell: SubmissionLinkCellRender,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.75,
    renderCell: DoneCellRender,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 6,
    sortable: false,
    renderCell: ProblemLinkCellRender,
  },
  {
    field: '_time',
    headerName: 'Time',
    flex: 3,
    sortable: false,
    renderCell: ({ row }) => `${formatDate(row.startAt, 'DDMMYYYYhhmm')} - ${formatDate(row.endAt, 'DDMMYYYYhhmm')}`,
  },
  {
    field: 'level',
    headerName: 'Difficulty',
    flex: 1,
    sortable: false,
    renderCell: ({ value }) => <Label color={problemLevelColor[value]}>{ProblemLevel[value]}</Label>,
  },
  {
    field: 'multiplier',
    headerName: 'Score',
    type: 'number',
    flex: 1,
    renderCell: ({ value }) => value * 100,
  },
];

export default function ProblemList() {
  const { showError } = useToast();
  const { contestId = 0 } = useParams();

  const [{ filter, data, total }, { getData, setFilterField, setFilter }] = usePagination({
    getDataFunc: CodeService.getProblemList,
    sortFieldMap: (fieldName) => `p.${fieldName}`,
    defaultSortModel: [{ field: 'code', sort: 'asc' }],
  });

  useEffect(() => {
    if (!isNil(filter.contestId)) {
      getDataDebounced({ ...filter });
    }
  }, [filter]);

  useEffect(() => {
    setFilterField('contestId')(contestId);
  }, [contestId]);

  const getDataDebounced = useDebounce(async (_filter) => {
    try {
      await getData(_filter);
    } catch (e) {
      showError(e.message);
    }
  }, 500);

  return (
    <>
      <Toolbar query={filter.query} setQuery={setFilterField('query')} getData={() => getDataDebounced(filter)} />
      <DataGridPremium
        autoHeight
        disableSelectionOnClick
        sortingMode="server"
        paginationMode="server"
        density="comfortable"
        pagination
        disableColumnMenu
        columns={columns}
        sortModel={filter.sortModel}
        rows={data}
        rowCount={total}
        paginationModel={filter}
        onPaginationModelChange={(model) => setFilter({ ...filter, ...model })}
        pageSizeOptions={[10, 20, 50]}
      />
    </>
  );
}

const Toolbar = ({ query, setQuery, getData }) => (
  <Box sx={{ mt: 2 }} display="flex" alignItems="end" justifyContent="end">
    <Tooltip title="Refresh">
      <IconButton onClick={getData} sx={{ mr: 2 }}>
        <Icon children="autorenew" />
      </IconButton>
    </Tooltip>
    <TextField
      type="search"
      value={query}
      onChange={(e) => setQuery(e.target.value)}
      size="small"
      placeholder="Search by name"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon children="search" />
          </InputAdornment>
        ),
      }}
    />
  </Box>
);
