import API_CONFIG from 'routes/apiConfig';
import HttpClient from './HttpClient';

class UserService {
  static getPublicProfile(username) {
    return HttpClient.get(API_CONFIG.PUBLIC_PROFILE.replace(':username', username));
  }

  static updateUserProfile(payload) {
    return HttpClient.put(API_CONFIG.UPDATE_PROFILE, payload);
  }

  static updateUserAvatar(url) {
    return HttpClient.put(API_CONFIG.UPDATE_AVATAR, { url });
  }

  static changePassword(payload) {
    return HttpClient.put(API_CONFIG.CHANGE_PASSWORD, payload);
  }

  static getListUsers(params) {
    return HttpClient.get(API_CONFIG.GET_USER_LIST, { params });
  }

  static getUserById(id) {
    return HttpClient.get(API_CONFIG.GET_USER.replace(':id', id));
  }

  static updateUser(id, payload) {
    return HttpClient.put(API_CONFIG.UPDATE_USER.replace(':id', id), payload);
  }
}

export default UserService;
