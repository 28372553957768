import { Box, Button, Card, CardContent, Stack, CardMedia, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useState } from 'react';
import { MotionInView, varFadeInUp } from '@components/animate';

export default function TrainingSyllabus({ data }) {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <Box>
      <Stepper nonLinear activeStep={activeStep} alternativeLabel>
        {data.map((step, index) => (
          <Step key={index} sx={{ cursor: 'pointer' }} completed={index < activeStep}>
            <StepLabel onClick={() => setActiveStep(index)}>{step.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <MotionInView variants={varFadeInUp}>
        <Card sx={{ my: 5 }}>
          <CardContent>
            <Box width="100%" mb={2} display="flex" justifyContent="space-between">
              <Button variant="outlined" disabled={activeStep === 0} onClick={() => setActiveStep(activeStep - 1)}>
                Trước
              </Button>
              <Button
                variant="contained"
                disabled={activeStep === data.length - 1}
                onClick={() => setActiveStep(activeStep + 1)}
              >
                {activeStep === data.length - 1 ? 'Hoàn thành' : 'Sau'}
              </Button>
            </Box>
            <Typography paragraph align="justify" my={5}>
              {data[activeStep].description}
            </Typography>
            {data[activeStep].imageList && (
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                {data[activeStep].imageList.map((image, index) => (
                  <Card key={index} sx={{ flex: 1, borderRadius: '8px' }}>
                    <CardMedia sx={{ aspectRatio: '3 / 2' }} image={image} title={index} />
                  </Card>
                ))}
              </Stack>
            )}
          </CardContent>
        </Card>
      </MotionInView>
    </Box>
  );
}
