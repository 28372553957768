import { ProfileAvatar } from '@components';
import { Box, Icon, IconButton, InputAdornment, Link, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { CodeService } from 'services';
import { useEffect, useMemo } from 'react';
import { useAuth, useDebounce, usePagination, useToast } from 'hooks';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ROUTES from 'routes/paths';
import { keyBy } from 'lodash';
import { CONTEST_TYPE, USER_ROLES } from 'utils/constants';
import ReactInterval from 'react-interval';
import { ContestBanner } from 'pages/Dashboard/contest/components/ContestBanner';
import { formatDate } from 'utils';
import { ScoreCell } from './ScoreCell';

const persistentColumns = [
  {
    field: 'rank',
    headerName: '#',
    width: 50,
    align: 'center',
    headerAlign: 'center',
    resizable: false,
    valueGetter: ({ value }) => value ?? '-',
    renderCell: ({ value }) => (value < 4 ? <Typography variant="h5" color="primary.dark" children={value} /> : value),
  },
  {
    field: 'user',
    headerName: 'User',
    width: 300,
    renderCell: ({ value }) => (
      <Link component={RouterLink} to={ROUTES.USER_PROFILE.replace(':username', value?.username)} underline="none">
        <Stack direction="row" alignItems="center" spacing={2}>
          <ProfileAvatar avatar={value.avatar} fullName={value?.full_name ?? value?.username ?? ''} />
          <Typography>{value?.full_name}</Typography>
        </Stack>
      </Link>
    ),
  },
  {
    field: 'mood',
    headerName: 'Mood',
    width: 200,
    renderCell: ({ row }) => row?.user?.mood,
  },
];

export default function RankingTable({ problems = [], contest }) {
  const { showError } = useToast();
  const { contestId = 0 } = useParams();

  const [, { hasRole }] = useAuth();
  const isManager = hasRole(USER_ROLES.Manager);

  const [{ filter, data, total }, { getData, setFilterField, setFilter }] = usePagination({
    getDataFunc: (params) => CodeService.getLeaderboard(contestId, params, isManager),
    sortFieldMap: (fieldName) => `r.${fieldName}`,
  });

  useEffect(() => {
    getDataDebounced({ ...filter });
  }, [filter]);

  useEffect(() => {
    if (!hasRole(USER_ROLES.Manager) && contest?.limitedTopRank) {
      setFilterField('pageSize')(contest?.limitedTopRank);
      setFilterField('offset')(0);
    }
  }, [contest]);

  const getDataDebounced = useDebounce(async (_filter) => {
    try {
      await getData(_filter);
    } catch (e) {
      showError(e.message);
    }
  }, 500);

  const columns = useMemo(
    () =>
      [
        ...persistentColumns,
        {
          field: 'score',
          headerName: 'Score',
          sortable: false,
          width: 100,
          headerAlign: 'center',
          renderCell: ({ value, row }) => (
            <ScoreCell score={contest?.rule === CONTEST_TYPE.ACM ? value / 100 : value} time={row.time} />
          ),
        },
        ...problems
          .sort((p1, p2) => p1.code.localeCompare(p2.code))
          .map(({ code, id, firstSolved, multiplier }) => ({
            field: `problem.${code}`,
            headerName: code,
            width: 100,
            headerAlign: 'center',
            renderCell: ({ row }) => {
              const record = row?.records?.[id];
              if (record) {
                const maxScore = multiplier * 100;
                if (record.score === maxScore) {
                  record.bgColor = record.time === firstSolved ? '#007B55' : '#00AB55';
                } else if (record.tried) {
                  record.bgColor = record.score ? '#AAF27F' : '#FFA48D';
                }
              }
              return (
                <ScoreCell
                  score={contest?.rule === CONTEST_TYPE.ACM ? record?.time : record?.score}
                  bgColor={record?.bgColor}
                  tried={record?.tried}
                />
              );
            },
          })),
      ].map((e) => ({ ...e, sortable: false, filterable: false, aggregable: false, groupable: false })),
    [problems, contest],
  );

  return (
    <>
      <ReactInterval callback={() => getDataDebounced(filter)} timeout={10000} enabled />
      <Box>
        <ContestBanner type="horizontal" />
      </Box>
      <Toolbar
        query={filter.query}
        setQuery={setFilterField('query')}
        getData={() => getDataDebounced(filter)}
        contest={contest}
      />
      <Box sx={{ height: '95vh' }}>
        <DataGridPremium
          disableSelectionOnClick
          disableRowSelectionOnClick
          sortingMode="server"
          paginationMode="server"
          density="comfortable"
          pagination
          columns={columns}
          sortModel={filter.sortModel}
          rows={data.map(({ records, ...e }) => ({ ...e, records: keyBy(records, 'problem_id') }))}
          rowCount={hasRole(USER_ROLES.Manager) ? total : contest?.limitedTopRank || total}
          pinnedColumns={{ left: ['rank', 'user', 'mood', 'score'] }}
          paginationModel={filter}
          onPaginationModelChange={(model) => setFilter({ ...filter, ...model })}
          pageSizeOptions={[10, 20, 50]}
          getRowId={(row) => row.user_id}
        />
      </Box>
    </>
  );
}

const Toolbar = ({ query, setQuery, getData, contest }) => (
  <Box display="flex" alignItems="center" justifyContent="space-between">
    {contest && (
      <Typography variant="h5">
        Leaderboard - {contest.name}{' '}
        {new Date(contest.closeRankAt) < new Date() && (
          <Typography display="inline" variant="subtitle2" color="secondary">
            {`Frozen at ${formatDate(contest.closeRankAt, 'DDMMYYYYhhmm')}`}
          </Typography>
        )}
      </Typography>
    )}
    <Box>
      <Tooltip title="Refresh">
        <IconButton onClick={getData} sx={{ mr: 2 }}>
          <Icon children="autorenew" />
        </IconButton>
      </Tooltip>
      <TextField
        type="search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        size="small"
        placeholder="Search by name"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon children="search" />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  </Box>
);
