import { Stack, Typography } from '@mui/material';

export const ScoreCell = ({ score, time, bgColor, tried }) => (
  <Stack
    direction="column"
    alignItems="center"
    justifyContent="center"
    width="100%"
    bgcolor={bgColor}
    height="100%"
    py={1}
  >
    <Typography variant="h6">{score ?? '-'}</Typography>
    <Typography variant="body2">
      {time} {Boolean(tried) && `${tried} ${tried > 1 ? 'tries' : 'try'}`}
    </Typography>
  </Stack>
);
