import { ProfileAvatar } from '@components';
import { useMemo, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Icon } from '@mui/material';
import { MIconButton } from '@components/@material-extend';
import MenuPopover from '@components/MenuPopover';
import useAuth from 'hooks/useAuth';
import ROUTES from 'routes/paths';
import { USER_ROLES } from 'utils/constants';
import { useTranslation } from 'react-i18next';

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [{ user }, { logOut, hasRole }] = useAuth();
  const isAdmin = hasRole(USER_ROLES.Manager);
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const adminMenu = useMemo(
    () => (isAdmin ? [{ label: 'Admin', icon: 'admin_panel_settings', linkTo: ROUTES.ADMIN_ROOT }] : []),
    [isAdmin],
  );

  const menuOptions = useMemo(
    () => [
      {
        label: 'Profile',
        icon: 'person',
        linkTo: ROUTES.USER_PROFILE.replace(':username', user['username']),
      },
      { label: 'Settings', icon: 'settings', linkTo: ROUTES.USER_SETTINGS },
      ...adminMenu,
    ],
    [user],
  );

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <ProfileAvatar alt="My Avatar" avatar={user?.avatar} fullName={user?.fullName ?? user?.username ?? ''} />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.fullName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />
        {menuOptions.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              children={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {t(option.label)}
          </MenuItem>
        ))}
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={() => logOut()}>
            {t('Logout')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
