import { Suspense } from 'react';
import LoadingScreen from '@components/LoadingScreen';

const Loadable = (Component) => (props) =>
  (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            top: 0,
            left: 0,
            width: 1,
            zIndex: 9999,
            position: 'fixed',
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
