import { SocketContextProvider } from 'contexts/SocketContext';
import ROUTES from 'routes/paths';
import DashboardLayout from 'layouts/dashboard';
import AuthGuard from 'guards/AuthGuard';
import { adminSidebar } from 'layouts/dashboard/SidebarConfig';
import { USER_ROLES } from 'utils/constants';
import { ContestList, CreateContest, UpdateContest } from './ManageContest';
import { ListUser, UpdateUser } from './ManageUser';
import { Editor } from './Editor';

const ADMIN_ROUTES = {
  path: ROUTES.ADMIN_ROOT,
  element: (
    <AuthGuard role={USER_ROLES.Manager}>
      <SocketContextProvider>
        <DashboardLayout sidebarConfig={adminSidebar} />
      </SocketContextProvider>
    </AuthGuard>
  ),
  children: [
    {
      path: ROUTES.ADMIN_USER_LIST,
      element: <ListUser />,
    },
    {
      path: ROUTES.ADMIN_UPDATE_USER,
      element: <UpdateUser />,
    },
    {
      path: ROUTES.ADMIN_CONTEST_LIST,
      element: <ContestList />,
    },
    {
      path: ROUTES.ADMIN_CREATE_CONTEST,
      element: <CreateContest />,
      exact: true,
    },
    {
      path: ROUTES.ADMIN_UPDATE_CONTEST,
      element: <UpdateContest />,
    },
    {
      path: ROUTES.ADMIN_EDITOR,
      element: <Editor />,
    },
  ],
};

export default ADMIN_ROUTES;
