import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import remarkEmoji from 'remark-emoji';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import rehypeRaw from 'rehype-raw';
import { styled } from '@mui/material/styles';
import { Divider, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';

const MarkdownWrapperStyle = styled('div')(({ theme }) => {
  const isLight = theme.palette.mode === 'light';

  return {
    // List
    '& ul, & ol': {
      ...theme.typography.body1,
      paddingLeft: theme.spacing(5),
      '& li': {
        lineHeight: 2,
      },
    },

    // Blockquote
    '& blockquote': {
      lineHeight: 1.5,
      fontSize: '1.5em',
      margin: '40px auto',
      position: 'relative',
      fontFamily: 'Georgia, serif',
      padding: theme.spacing(3, 3, 3, 8),
      borderRadius: theme.shape.borderRadiusMd,
      backgroundColor: theme.palette.background.neutral,
      color: `${theme.palette.text.secondary} !important`,
      [theme.breakpoints.up('md')]: {
        width: '80%',
      },
      '& p, & span': {
        marginBottom: '0 !important',
        fontSize: 'inherit !important',
        fontFamily: 'Georgia, serif !important',
        color: `${theme.palette.text.secondary} !important`,
      },
      '&:before': {
        left: 16,
        top: -8,
        display: 'block',
        fontSize: '3em',
        content: '"\\201C"',
        position: 'absolute',
        color: theme.palette.text.disabled,
      },
    },

    // Code Block
    '& pre, & pre > code': {
      fontSize: 16,
      overflowX: 'auto',
      whiteSpace: 'pre',
      padding: theme.spacing(2),
      color: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[isLight ? 900 : 500_16],
    },
    '& code': {
      fontSize: 14,
      borderRadius: 4,
      whiteSpace: 'pre',
      padding: theme.spacing(0.2, 0.5),
      color: theme.palette.warning[isLight ? 'darker' : 'lighter'],
      backgroundColor: theme.palette.warning[isLight ? 'lighter' : 'darker'],
      '&.hljs': { padding: 0, backgroundColor: 'transparent' },
    },
  };
});

const components = {
  hr: ({ ...props }) => <Divider sx={{ my: 3 }} {...props} />,
  // Table
  table: ({ ...props }) => (
    <Table
      {...props}
      sx={{
        border: 1,
      }}
    />
  ),
  tbody: ({ ...props }) => <TableBody {...props} />,
  thead: ({ ...props }) => (
    <TableHead
      {...props}
      sx={{
        borderBottom: 1,
        borderRight: 1,
      }}
    />
  ),
  tr: ({ ...props }) => (
    <TableRow
      {...props}
      sx={{
        borderBottom: 1,
      }}
    />
  ),
  td: ({ ...props }) => (
    <TableCell
      {...props}
      sx={{
        borderRight: 1,
      }}
    />
  ),
};

// ----------------------------------------------------------------------

export default function Markdown({ ...other }) {
  return (
    <MarkdownWrapperStyle>
      <ReactMarkdown
        plugins={[remarkMath, remarkEmoji]}
        rehypePlugins={[rehypeRaw, rehypeKatex]}
        components={components}
        {...other}
      />
    </MarkdownWrapperStyle>
  );
}
