import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import ROUTES from 'routes/paths';
import Loadable from '@components/Loadable';
import AUTH_ROUTES from 'pages/Auth';
import DASHBOARD_ROUTES from 'pages/Dashboard';
import USER_ROUTES from 'pages/User';
import MAIN_ROUTES from 'pages/Main';
import ADMIN_ROUTES from 'pages/Admin';
import PublicContestLeaderboard from 'pages/Main/PublicContestLeaderboard';

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    DASHBOARD_ROUTES,
    AUTH_ROUTES,
    USER_ROUTES,
    MAIN_ROUTES,
    ADMIN_ROUTES,
    {
      path: ROUTES.PUBLIC_CONTEST_LEADERBOARD,
      element: <PublicContestLeaderboard />,
    },
    { path: ROUTES.FORBIDDEN, element: <Navigate to="/dashboard" /> },
    { path: '*', element: <Navigate to="/404" replace /> },
    { path: ROUTES.NOT_FOUND, element: <NotFound /> },
  ]);
}

// Main
const NotFound = Loadable(lazy(() => import('pages/Error/Page404')));
