const ROUTES = Object.freeze({
  /**
   * Main routes
   */
  HOME: '/',
  ACTIVITIES: '/activities',
  TRAINING: '/training',
  ABOUT: '/about',
  CONTACT: '/contact',
  MEMBER: '/member',
  NOT_FOUND: '/404',
  FORBIDDEN: '/403',
  PUBLIC_CONTEST_LEADERBOARD: '/leaderboard/:contestId',

  /**
   * User routes
   */
  USER_ROOT: '/u',
  USER_PROFILE: '/u/:username',
  USER_SETTINGS: '/u/account',

  /**
   * Auth routes
   */
  AUTH_ROOT: '/auth',
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  VERIFY_ACCOUNT: '/auth/verify-account',
  RESET_PASSWORD: '/auth/reset-password',

  /**
   * Dashboard routes
   */
  DASHBOARD_ROOT: '/dashboard',
  CONTEST_LIST: '/dashboard/contests',
  CONTEST_DETAIL: '/dashboard/contests/:contestId',
  CONTEST_LEADERBOARD: '/dashboard/contests/:contestId/leaderboard',
  CONTEST_PROBLEM_LIST: '/dashboard/contests/:contestId/problems',
  CONTEST_ANNOUNCEMENT_LIST: '/dashboard/contests/:contestId/announcements',
  CONTEST_SUBMISSION_LIST: '/dashboard/contests/:contestId/submissions',

  PROBLEM_LIST: '/dashboard/problems',
  PROBLEM_DETAIL: '/dashboard/problems/:problemId',

  SUBMISSION_LIST: '/dashboard/submissions',

  LEADERBOARD: '/dashboard/leaderboard',

  /**
   * Admin routes
   */
  ADMIN_ROOT: '/admin',
  ADMIN_CONTEST_LIST: '/admin/contests',
  ADMIN_UPDATE_CONTEST: '/admin/contests/:contestId',
  ADMIN_CREATE_CONTEST: '/admin/contests/create',
  ADMIN_USER_LIST: '/admin/users',
  ADMIN_UPDATE_USER: '/admin/users/:userId',
  ADMIN_FEEDBACK_LIST: '/admin/feedback',
  ADMIN_SITE_SETTINGS: '/admin/settings',
  ADMIN_EDITOR: '/admin/editor',
});

export default ROUTES;
