import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';

import combinedReducer from './reducers';

const persistConfig = {
  key: '@root',
  storage: localStorage,
};

const persistedReducer = persistReducer(persistConfig, combinedReducer);

const store = configureStore({ reducer: persistedReducer });
const persistor = persistStore(store);

export { store, persistor };
