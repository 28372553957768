import { actions } from '@redux/reducers/appReducer';
import { useDispatch, useSelector } from 'react-redux';

const useAppState = () => {
  const appState = useSelector((state) => state['appReducer']);
  const dispatch = useDispatch();
  const showLoading = () => dispatch(actions.showLoading());
  const hideLoading = () => dispatch(actions.hideLoading());

  return [appState, { showLoading, hideLoading }];
};

export default useAppState;
