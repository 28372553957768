import createAvatar from 'utils/createAvatar';
import PropTypes from 'prop-types';
import StringAvatar from './StringAvatar';

ProfileAvatar.propTypes = {
  avatar: PropTypes.string,
  fullName: PropTypes.string.isRequired,
};

export default function ProfileAvatar({ avatar, fullName, ...other }) {
  return (
    <StringAvatar src={avatar} alt={fullName} color={avatar ? 'default' : createAvatar(fullName).color} {...other}>
      {createAvatar(fullName).name}
    </StringAvatar>
  );
}
