import { useCallback, useState } from 'react';
import useAppState from './useAppState';

/**
 * @param { getDataFunc: function }
 */
const usePagination = ({ getDataFunc, sortFieldMap, defaultPage, defaultPageSize, defaultSortModel }) => {
  const [, { showLoading, hideLoading }] = useAppState();
  const [filter, setFilter] = useState({
    page: defaultPage ?? 0,
    pageSize: defaultPageSize ?? 20,
    sortModel: defaultSortModel ?? [{ field: 'id', sort: 'desc' }],
    query: '',
  });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const setFilterField = (fieldName) => (value) => setFilter((_filter) => ({ ..._filter, [fieldName]: value }));

  const getData = useCallback(
    async (_filter = {}) => {
      const { page, pageSize, sortModel, ...others } = _filter;

      const limit = pageSize;
      const offset = pageSize * page;
      const sort = sortModel.map((e) => `${sortFieldMap?.(e.field) ?? e.field}:${e.sort}`).join(',') || undefined;
      showLoading();
      try {
        const {
          data,
          meta: { total },
        } = await getDataFunc({
          limit,
          offset,
          sort,
          ...others,
        });
        setData(data);
        setTotal(total);
      } finally {
        hideLoading();
      }
    },
    [filter],
  );

  return [
    { filter, data, total },
    { setFilter, setFilterField, getData },
  ];
};

export default usePagination;
