import { useSnackbar } from 'notistack';
import i18next from 'i18next';

export const useToast = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showToast = (msg, options) => enqueueSnackbar(msg, options);

  const showSuccess = (msg, options) =>
    enqueueSnackbar(msg, {
      variant: 'success',
      ...options,
    });

  const showError = (msg, options) =>
    enqueueSnackbar(msg || i18next.t('Oops, Something went wrong!'), {
      variant: 'error',
      ...options,
    });

  const showInfo = (msg, options) =>
    enqueueSnackbar(msg, {
      variant: 'info',
      ...options,
    });

  const showWarning = (msg, options) =>
    enqueueSnackbar(msg, {
      variant: 'warning',
      ...options,
    });

  const hideToast = closeSnackbar;

  return {
    showToast,
    showSuccess,
    showError,
    showInfo,
    showWarning,
    hideToast,
  };
};

export default useToast;
