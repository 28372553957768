import { styled } from '@mui/styles';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import Carousel from 'react-material-ui-carousel';

const APP_BAR_DESKTOP = 88;

const RootStyle = styled(Box)(({ imageUrl }) => ({
  height: '100vh',
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  transition: 'background-image 0.5s ease-in-out',
}));

export default function SubpageHeroSection({ data }) {
  const [current, setCurrent] = useState(0);

  const handleNext = () => {
    if (current === data.length - 1) {
      setCurrent(0);
    } else {
      setCurrent(current + 1);
    }
  };

  const handlePrev = () => {
    if (current === 0) {
      setCurrent(data.length - 1);
    } else {
      setCurrent(current - 1);
    }
  };

  return (
    <RootStyle imageUrl={data[current].imageUrl}>
      <Box
        width="100%"
        height="100%"
        sx={{
          marginTop: `${APP_BAR_DESKTOP - 16}px`,
          backdropFilter: 'blur(12px)',
          '-webkit-backdrop-filter': 'blur(12px)',
        }}
      >
        <Container maxWidth="xl" sx={{ height: '100%' }}>
          <Grid container spacing={4} height="100%">
            <Grid item xs={12} sm={5}>
              <Box
                display="flex"
                width="100%"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ color: data[current].color }}
              >
                <Typography variant="h4" component="h1">
                  {data[current].title}
                </Typography>
                <Typography variant="body1" sx={{ textAlign: 'justify', fontSize: '1.25rem' }} paragraph>
                  {data[current].description}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={7}>
              <Box
                display="flex"
                width="100%"
                height={{ sm: '100%' }}
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Carousel
                  fullHeightHover
                  sx={{ width: '100%' }}
                  // indicators={false}
                  // autoPlay={false}
                  swipe={false}
                  navButtonsAlwaysVisible
                  next={handleNext}
                  prev={handlePrev}
                >
                  {data.map((item, i) => (
                    <Item key={i} item={item} />
                  ))}
                </Carousel>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </RootStyle>
  );
}

const Item = ({ item }) => (
  <img
    src={item.imageUrl}
    alt={item.title}
    style={{ objectFit: 'cover', margin: '0 auto', borderRadius: 12, aspectRatio: '3 / 2' }}
  />
);
