// i18n
import 'locales/i18n';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
// scroll bar
import ReactDOM from 'react-dom';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// contexts
import { SettingsProvider } from 'contexts/SettingsContext';
import { CollapseDrawerProvider } from 'contexts/CollapseDrawerContext';
//
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { store, persistor } from '@redux';
import { PersistGate } from 'redux-persist/integration/react';
import { AxiosInterceptor } from 'services/HttpClient';
import { LicenseInfo } from '@mui/x-data-grid-premium';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// ----------------------------------------------------------------------

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <SettingsProvider>
            <CollapseDrawerProvider>
              <SnackbarProvider
                dense
                preventDuplicate
                autoHideDuration={2000}
                maxSnack={5}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <BrowserRouter>
                    <AxiosInterceptor>
                      <App />
                    </AxiosInterceptor>
                  </BrowserRouter>
                </LocalizationProvider>
              </SnackbarProvider>
            </CollapseDrawerProvider>
          </SettingsProvider>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root'),
);

LicenseInfo.setLicenseKey(
  `391a12471c0e3ea5b376623c943206feTz1GQ0ssRT0yNTI0NjA4MDAwMDAwLFM9cHJlbWl1bSxMTT1wZXJwZXR1YWwsS1Y9Mg==`,
);
serviceWorkerRegistration.unregister();
