import axios from 'axios';
import { useAuth, useToast } from 'hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'routes/paths';
import { LOCAL_STORAGE_KEYS } from 'utils/constants';
import APP_CONFIG from '@config';
import { storage } from 'utils';
import LoadingScreen from '@components/LoadingScreen';
import AuthService from './AuthService';

const HttpClient = axios.create({
  baseURL: APP_CONFIG.apiBaseUrl,
  timeout: 30000,
});

HttpClient.interceptors.request.use(
  async (request) => {
    const accessToken = storage.get(LOCAL_STORAGE_KEYS.accessToken);
    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }
    return request;
  },
  (err) => Promise.reject(err),
);

HttpClient.interceptors.request.use(
  async (request) => {
    const accessToken = storage.get(LOCAL_STORAGE_KEYS.accessToken);
    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }
    return request;
  },
  (err) => Promise.reject(err),
);

export const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();
  const [, { logOut }] = useAuth();
  const { showError } = useToast();
  const [initiated, setInitiated] = useState(false);

  const retryRequest = async (request) => {
    request['_retry'] = true;
    const { data } = await AuthService.refreshToken().catch(() => {
      showError('Your session has expired');
      logOut();
    });
    storage.set(LOCAL_STORAGE_KEYS.accessToken, data.accessToken);
    request.headers.Authorization = `Bearer ${data.accessToken}`;
    return HttpClient(request);
  };

  useEffect(() => {
    const responseInterceptor = (response) => response.data;

    const errorInterceptor = async (e) => {
      const { response, config: originalRequest } = e;
      if (!response?.status) {
        return Promise.reject(new Error('Network error'));
      }
      if (response.status === 401 && !originalRequest['_retry'] && storage.get(LOCAL_STORAGE_KEYS.refreshToken)) {
        return retryRequest(originalRequest);
      }
      if (response.status === 404) {
        navigate(ROUTES.NOT_FOUND);
      }
      if (response.status === 403) {
        navigate(ROUTES.FORBIDDEN);
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        ...response?.data,
        status: response?.status,
      });
    };

    const interceptor = HttpClient.interceptors.response.use(responseInterceptor, errorInterceptor);
    setInitiated(true);
    return () => HttpClient.interceptors.response.eject(interceptor);
  }, []);

  return initiated ? (
    children
  ) : (
    <LoadingScreen
      sx={{
        top: 0,
        left: 0,
        width: 1,
        zIndex: 9999,
        position: 'fixed',
      }}
    />
  );
};

export default HttpClient;
