export const LOCAL_STORAGE_KEYS = Object.freeze({
  accessToken: '@accessToken',
  refreshToken: '@refreshToken',
  user: '@user',
  i18Lang: 'i18nextLng',
});

export const USER_ROLES = Object.freeze({
  unknown: 'Unknown',
  0: 'User',
  1: 'Manager',
  2: 'Admin',
  User: 0,
  Manager: 1,
  Admin: 2,
});

export const CONTEST_TYPE = Object.freeze({
  ACM: 'ACM',
  OI: 'OI',
});

export const CONTEST_STATUS = Object.freeze({
  UPCOMING: 'Up Coming',
  ONGOING: 'On Going',
  ENDED: 'Ended',
});

export const SUBMISSION_STATUS = Object.freeze({
  IQ: 'In Queue', // In Queue
  IP: 'In Processing', // In Processing
  AC: 'Accepted', // Accepted
  PAC: 'Partial accepted', // Partial accepted
  WA: 'Wrong Answer', // Wrong Answer
  CE: 'Compile Error', // Compile Error
  RTE: 'Runtime Exception', // Runtime Exception
  TLE: 'Time Limit Exceeded', // Time Limit Exceeded
  IE: 'Internal Error', // Internal Error
  RJ: 'Rejudging', // Rejudge
});

export const PROGRAM_LANGUAGE = Object.freeze({
  C: {
    name: 'C',
    type: 'text/x-csrc',
  },
  CPP: {
    name: 'CPP',
    type: 'text/x-c++src',
  },
  JAVA: {
    name: 'JAVA',
    type: 'text/x-java',
  },
  PYTHON: {
    name: 'PYTHON',
    type: 'text/x-python',
  },
  NODE: {
    name: 'NODE',
    type: 'text/javascript',
  },
  PASCAL: {
    name: 'PASCAL',
    type: 'text/x-pascal',
  },
});

export const CODE_TEMPLATE = Object.freeze({
  C: `#include <stdio.h>
// IT Club PTIT Hanoi

int main() {
\t// Your code goes here
\t
\treturn 0;
}
`,
  CPP: `#include <iostream>
using namespace std;
// IT Club PTIT Hanoi

int main() {
\t// Your code goes here
\t
\treturn 0;
}
`,
  JAVA: `// Do not change class name!

import java.util.*;
import java.lang.*;
import java.io.*;
// IT Club PTIT Hanoi

class Execute
{
\tpublic static void main (String[] args) throws java.lang.Exception
\t{
\t\t// Your code goes here
\t}
}
`,
  PYTHON: `# IT Club PTIT Hanoi
`,
  NODE: `process.stdin.resume();
process.stdin.setEncoding('utf8');
// IT Club PTIT Hanoi

// Your code goes here
`,
  PASCAL: `program itptit;
begin
\t//IT Club PTIT Hanoi
\t(* Your code goes here *)
end.`,
});
