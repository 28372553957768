import Ranking from 'pages/Dashboard/ranking/Ranking';
import { Box } from '@mui/material';

export default function PublicContestLeaderboard() {
  return (
    <Box px={5} pt={2}>
      <Ranking />
    </Box>
  );
}
