export default {
  /**
   * @param string
   * @returns {string} base64String
   */
  encode: (string) => btoa(string),

  /**
   * @param {string} base64String
   * @returns {string}
   */
  decode: (base64String) => {
    try {
      return atob(base64String);
    } catch (e) {
      return '';
    }
  },
};
