import logo from 'assets/images/logo-single-new.png';

class MockService {
  static buildData(data) {
    return {
      data,
      meta: {
        total: data.length,
      },
    };
  }

  static getAnnouncementList(contestId) {
    return new Promise((resolve) => resolve(MockService.buildData(announcementData[contestId] ?? [])));
  }

  static getBanner(contestId) {
    return new Promise((resolve) => resolve(MockService.buildData(banner[contestId] ?? [])));
  }

  static getSquareBanner(contestId) {
    return new Promise((resolve) =>
      resolve(
        MockService.buildData(
          squareBanner[contestId] ?? [
            {
              name: 'ITPTIT',
              image: logo,
            },
          ],
        ),
      ),
    );
  }
}

export default MockService;

const announcementData = {};

const banner = {
  10023: [
    {
      name: 'FPT Software',
      image: 'https://i.ibb.co/fNd24Bt/fpt.jpg',
    },
    {
      name: 'Hybrid Technologies',
      image: 'https://i.ibb.co/fQLqbVC/hybrid.jpg',
    },
    {
      name: 'Zenlish',
      image: 'https://i.ibb.co/tHkMBL0/WWEB-HD.jpg',
    },
  ],
  10028: [
    {
      name: 'Zenlish',
      image: 'https://i.ibb.co/tHkMBL0/WWEB-HD.jpg',
    },
  ],
  10029: [
    {
      name: 'Zenlish',
      image: 'https://i.ibb.co/tHkMBL0/WWEB-HD.jpg',
    },
  ],
  10030: [
    {
      name: 'Zenlish',
      image: 'https://i.ibb.co/tHkMBL0/WWEB-HD.jpg',
    },
  ],
  10031: [
    {
      name: 'Zenlish',
      image: 'https://i.ibb.co/tHkMBL0/WWEB-HD.jpg',
    },
  ],
  10032: [
    {
      name: 'Zenlish',
      image: 'https://i.ibb.co/tHkMBL0/WWEB-HD.jpg',
    },
  ],
  10033: [
    {
      name: 'Zenlish',
      image: 'https://i.ibb.co/tHkMBL0/WWEB-HD.jpg',
    },
  ],
  10034: [
    {
      name: 'Zenlish',
      image: 'https://i.ibb.co/tHkMBL0/WWEB-HD.jpg',
    },
  ],
  10035: [
    {
      name: 'Zenlish',
      image: 'https://i.ibb.co/tHkMBL0/WWEB-HD.jpg',
    },
  ],
};

const squareBanner = {
  10023: [
    {
      name: 'FPT Software',
      image: 'https://i.ibb.co/VN9dzSg/fpt.jpg',
    },
    {
      name: 'Zenlish',
      image: 'https://i.ibb.co/S6D9B1n/HD.jpg',
    },
    {
      name: 'Hybrid Technologies',
      image: 'https://i.ibb.co/VMpv04q/hybrid-technologies.jpg',
    },
  ],
};
