import { Field } from 'formik';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers-pro';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const FormikDatePicker = ({ type, ...props }) => (
  <Field name={props.name}>
    {({ field: { value }, form: { setFieldValue }, meta: { touched, error } }) =>
      type === 'datetime' ? (
        <DesktopDateTimePicker
          {...props}
          onChange={(value) => setFieldValue(props.name, value)}
          value={value}
          renderInput={(params) => (
            <TextField error={Boolean(touched && error)} helperText={touched && error} {...params} {...props} />
          )}
        />
      ) : (
        <DesktopDatePicker
          {...props}
          onChange={(value) => setFieldValue(props.name, value)}
          value={value}
          renderInput={(params) => (
            <TextField error={Boolean(touched && error)} helperText={touched && error} {...params} {...props} />
          )}
        />
      )
    }
  </Field>
);

FormikDatePicker.propTypes = {
  type: PropTypes.oneOf(['date', 'datetime']),
};

FormikDatePicker.defaultProps = {
  type: 'date',
};

export default FormikDatePicker;
