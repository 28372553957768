import ROUTES from 'routes/paths';
import MainLayout from 'layouts/main';
import Loadable from '@components/Loadable';
import { lazy } from 'react';
import TrainingPage from 'pages/Main/TrainingPage';

const LandingPage = Loadable(lazy(() => import('./LandingPage')));
const ActivitiesPage = Loadable(lazy(() => import('./ActivitiesPage')));
const ComingSoon = Loadable(lazy(() => import('pages/Error/ComingSoon')));
const TuyenThanhVien = Loadable(lazy(() => import('./TuyenThanhVien')));
const ManageTuyenThanhVien = Loadable(lazy(() => import('./TuyenThanhVien/Manage')));

const MAIN_ROUTES = {
  path: ROUTES.HOME,
  element: <MainLayout />,
  children: [
    {
      path: ROUTES.HOME,
      element: <LandingPage />,
    },
    {
      path: ROUTES.TRAINING,
      element: <TrainingPage />,
    },
    {
      path: ROUTES.ACTIVITIES,
      element: <ActivitiesPage />,
    },
    {
      path: ROUTES.MEMBER,
      element: <ComingSoon />,
    },
    {
      path: ROUTES.CONTACT,
      element: <ComingSoon />,
    },
    {
      path: '/tuyen-thanh-vien',
      element: <TuyenThanhVien />,
    },
    {
      path: '/tuyen-thanh-vien/manage',
      element: <ManageTuyenThanhVien />,
    },
  ],
};
export default MAIN_ROUTES;
